<template>
  <div>
    <el-table :data="productPricesList" max-height="400" style="width: 100%;">
      <el-table-column :label="pricesListName">
        <el-table-column label="Date & Time" align="center">
          <template #default="slotProps">
            <span>{{ dayjs(slotProps.row.createdDate).format('DD/MM/YYYY hh:mmA') }}</span>
          </template>
        </el-table-column>
        <el-table-column label="User" align="center">
          <template #default="slotProps">
            <UserInfo :userId="slotProps.row.createdByUserId" v-if="slotProps.row.createdByUserId" />
          </template>
        </el-table-column>
        <el-table-column prop="price" label="Amount" align="center">
          <template #default="slotProps">
            <span style="font-weight: bold">SGD${{ slotProps.row.price.toLocaleString(undefined, {minimumFractionDigits: 2}) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Accept?" width="300" align="center" v-if="isLoggin() && pricesListName=='Offers'">
          <template #default="slotProps">
            <div v-if="slotProps.row.status == 'Pending'">
              <div v-if="isOfferOwner(slotProps.row.createdByUserId)">
                <el-button
                    class="custom-btn reject-btn"
                    style="position: unset;"
                    :disabled="cancelOfferLoader[slotProps.row.offerId]"
                    :loading="cancelOfferLoader[slotProps.row.offerId]"
                    @click="cancel(slotProps.row.offerId)"
                >
                    CANCEL
                </el-button>
              </div>
              <div v-else-if="isProductOwner(slotProps.row.createdByUserId)">
                <el-button
                    class="custom-btn accept-btn"
                    style="position: unset;"
                    :disabled="acceptOfferLoader[slotProps.row.offerId]"
                    :loading="acceptOfferLoader[slotProps.row.offerId]"
                    @click="accept(slotProps.row.offerId)"
                >
                    ACCEPT
                </el-button>
                <el-button
                    class="custom-btn reject-btn"
                    style="position: unset;"
                    :disabled="rejectOfferLoader[slotProps.row.offerId]"
                    :loading="rejectOfferLoader[slotProps.row.offerId]"
                    @click="reject(slotProps.row.offerId)"
                >
                    REJECT
                </el-button>
              </div>
              <div v-else>
                {{ slotProps.row.status }}
              </div>
            </div>
            <div v-else>
              {{ slotProps.row.status }}
            </div>
          </template>
        </el-table-column>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import productServices from '@/services/product-service';
import UserInfo from '@/components/Profile/UserInfo.vue';
import { ElMessage } from 'element-plus';
import { isLoggin, getAuthID } from '@/helpers';
import dayjs from 'dayjs';

export default {
  props: {
    productDetail: {
      type: Object,
      required: true,
    },
    productPricesList: {
      type: Array,
      required: true,
    },
  },
  components: {
    UserInfo,
  },
  setup(props) {
    const pricesListName = ref(null);
    const rejectOfferLoader = ref([]);
    const acceptOfferLoader = ref([]);
    const cancelOfferLoader = ref([]);
    const initialCall = async () => {
      if (props.productDetail.salesType === 'Fixed') {
        pricesListName.value = 'Offers';
      } else {
        pricesListName.value = 'Bids';
      }
    };

    onMounted(() => {
      initialCall();
    });

    const accept = (offerId) => {
      acceptOfferLoader.value[offerId] = true;
      productServices.acceptOffer(offerId).then(
        () => {
          initialCall();
          ElMessage.success({
            showClose: true,
            message: 'Accepted successfully',
          });
        },
        () => {
          ElMessage.error({
            showClose: true,
            message: 'Error',
          });
        },
      ).finally(() => {
        acceptOfferLoader.value[offerId] = false;
      });
    };

    const reject = (offerId) => {
      rejectOfferLoader.value[offerId] = true;
      productServices.rejectOffer(offerId).then(
        () => {
          initialCall();
          ElMessage.success({
            showClose: true,
            message: 'Rejected successfully',
          });
        },
        () => {
          ElMessage.error({
            showClose: true,
            message: 'Error',
          });
        },
      ).finally(() => {
        rejectOfferLoader.value[offerId] = false;
      });
    };

    const cancel = (offerId) => {
      cancelOfferLoader.value[offerId] = true;
      productServices.cancelOffer(offerId).then(
        () => {
          initialCall();
          ElMessage.success({
            showClose: true,
            message: 'Cancelled successfully',
          });
        },
        () => {
          ElMessage.error({
            showClose: true,
            message: 'Error',
          });
        },
      ).finally(() => {
        cancelOfferLoader.value[offerId] = false;
      });
    };

    const isOfferOwner = (userId) => {
      if (!userId || !getAuthID().userId || !props.productDetail.createdByUserId) {
        return false;
      }
      return userId !== props.productDetail.createdByUserId && getAuthID().userId === userId;
    };

    const isProductOwner = (userId) => {
      if (!userId || !getAuthID().userId || !props.productDetail.createdByUserId) {
        return false;
      }
      return userId !== props.productDetail.createdByUserId && getAuthID().userId === props.productDetail.createdByUserId;
    };

    return {
      // productPricesList,
      pricesListName,
      dayjs,
      accept,
      reject,
      cancel,
      isLoggin,
      isOfferOwner,
      isProductOwner,
      rejectOfferLoader,
      acceptOfferLoader,
      cancelOfferLoader,
    };
  },
};
</script>

<style lang="scss">
  .el-table {
    border-radius: 10px;
    margin: 2em 0;
  }
  .el-table thead.is-group th {
    background: none;
  }
  .el-table thead,
  .el-table__row {
    color: #000;
  }
  .el-table--border td,
  .el-table--border th,
  .el-table__body-wrapper,
  .el-table--border.is-scrolling-left~.el-table__fixed {
    border-right: none;
  }
</style>
