<template>
  <el-form
    class="custom-form"
    label-position="top"
    label-width="100px"
    :model="paymentData"
    :rules="paymentRules"
    ref="paymentForm"
    @keyup.enter="onSubmit"
    @submit.prevent
    style="height: 100%; flex-flow: column;"
    :style="{ display: isConfirmation ? 'flex' : 'block' }"
  >
    <div style="padding: 20px 0 20px 0;" class="hidden-sm-and-down">
      <el-breadcrumb separator=">">
        <el-breadcrumb-item>Transaction</el-breadcrumb-item>
        <el-breadcrumb-item>Summary</el-breadcrumb-item>
        <el-breadcrumb-item v-if="isConfirmation">Confirmation</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div v-if="!isConfirmation">
      <!-- address part -->
      <el-row>
        <el-col :span="24">
          <div>
            <span class="sub-header fs-20">Delivery Address</span>
            <br />
            <el-row :gutter="20">
              <el-col :span="24" :md="12">
                <div>
                  <TextInput
                    v-model="paymentData.firstName"
                    formProps="firstName"
                    formLabel="First Name"
                  />
                </div>
              </el-col>
              <el-col :span="24" :md="12">
                <div>
                  <TextInput
                    v-model="paymentData.lastName"
                    formProps="lastName"
                    formLabel="Last Name"
                  />
                </div>
              </el-col>
              <el-col :span="24">
                <div>
                  <TextInput
                    v-model="paymentData.postalCode"
                    formProps="postalCode"
                    formLabel="Postal Code"
                  />
                </div>
              </el-col>
              <el-col :span="24">
                <div>
                  <TextInput
                    v-model="paymentData.address1"
                    formProps="address1"
                    formLabel="Address 1"
                  />
                </div>
              </el-col>
              <el-col :span="24">
                <div>
                  <TextInput
                    v-model="paymentData.address2"
                    formProps="address2"
                    formLabel="Address 2"
                  />
                </div>
              </el-col>
              <el-col :span="24">
                <div>
                  <TextInput
                    v-model="paymentData.phoneNumber"
                    formProps="phoneNumber"
                    formLabel="Contact Number"
                  />
                </div>
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <div v-else style="flex: 1 1;" class="row-style">
      <div class="sub-header fs-16">Delivering To</div>
      <article style="padding-left: 15px; padding-top: 10px;">
        <div>{{ `${paymentData.firstName} ${paymentData.lastName}` }}</div>
        <div>{{ `(+65) ${paymentData.phoneNumber}` }}</div>
        <div>{{ paymentData.address1 }}</div>
        <div>{{ paymentData.address2 }}</div>
        <div>{{ `${paymentData.country} ${paymentData.postalCode}` }}</div>
      </article>
    </div>
    <div>
      <!-- buttons -->
      <el-row class="row-style">
        <el-col :span="24" :xs="{span: 24, offset: 0}">
          <div
            style="display: flex; justify-content: space-between; align-items: center;"
            :style="{ flexFlow: isMobileView ? 'column-reverse' : 'unset' }"
          >
            <span
              class="fs-16 fm-montserrat fw-700"
              style="cursor: pointer; text-transform: uppercase; text-align: center;"
              :style="{ paddingTop: isMobileView ? '30px' : 'unset' }"
              @click="backFunc"
            >{{ !isConfirmation ? 'Back to transactions' : 'Back to confirmation' }}</span>
            <el-button
              class="custom-btn primary-btn"
              @click="onSubmit($refs.paymentForm)"
              :disabled="isSubmitingPayment"
              :loading="isSubmitingPayment"
            >{{ !isConfirmation ? 'Proceed To Confirmation' : 'Proceed To Payment' }}</el-button>
          </div>
        </el-col>
      </el-row>
    </div>
  </el-form>
</template>

<script>
import { ref, computed } from 'vue';
import TextInput from '@/components/Share/TextInput.vue';
// import countryServices from '@/services/country-service';
import { useStore } from 'vuex';

export default {
  props: {
    onSubmit: {
      type: Function,
      required: true,
    },
    resetForm: {
      type: Function,
      required: true,
    },
    isConfirmation: {
      type: Boolean,
      required: true,
    },
    prodId: {
      type: String,
      required: true,
    },
    isSubmitingPayment: Boolean,
    backFunc: Function,
  },
  components: {
    TextInput,
  },
  setup() {
    const store = useStore();
    const isMobileView = computed(() => store.state.layout.isMobileView);

    const paymentData = ref({
      firstName: '',
      lastName: '',
      country: 'Singapore',
      phoneNumber: '',
      postalCode: '',
      address1: '',
      address2: '',
    });

    const paymentRules = ref({
      firstName: [
        {
          required: true,
          message: 'Please enter name',
        },
      ],
      lastName: [
        {
          required: true,
          message: 'Please enter name',
        },
      ],
      phoneNumber: [
        {
          required: true,
          message: 'Please enter phone Number',
        },
      ],
      postalCode: [
        {
          required: true,
          message: 'Please enter postal Code',
        },
      ],
      address1: [
        {
          required: true,
          message: 'Please enter address1',
        },
      ],
    });

    return {
      paymentData,
      paymentRules,
      isMobileView,
    };
  },
};
</script>
