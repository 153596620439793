import { MpApiIni, SetAuthHeader } from './api';

function retrieveChatRoom(userId) {
  return MpApiIni().get(`/api/mp/chat/v1/chatroom/${userId}`, SetAuthHeader())
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    });
}

function retrieveChatRoomMessages(chatRoomId, offset) {
  return MpApiIni().get(`/api/mp/chat/v1/chatroom/${chatRoomId}/retrievechatroommessage/${offset}`, SetAuthHeader())
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    });
}

function sendMessage(body) {
  return MpApiIni().post('/api/mp/chat/v1/chatroom/sendmessage', body, SetAuthHeader())
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    });
}

const services = {
  retrieveChatRoom,
  retrieveChatRoomMessages,
  sendMessage,
};

export default services;
