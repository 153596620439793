<template>
  <div v-if="userInfo">
    {{ userInfo.name }}
  </div>
  <div v-else>
    -
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import profileServices from '@/services/profile-service';

export default {
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  components: {
  },
  setup(props) {
    const userInfo = ref(null);

    onMounted(async () => {
      userInfo.value = await profileServices.getProfilebyUserId(props.userId);
    });

    return {
      userInfo,
    };
  },
};
</script>
