<template>
  <el-row v-loading="loading">
    <el-col :span="22" :offset="1" :md="{ span: 18, offset: 3 }" style="margin-bottom: 15px; margin-top: 50px">
      <div class="sub-header left-text" style="padding-bottom: 20px">All Products</div>

      <div class="custom-scrollbar" style="white-space: nowrap; overflow-x: scroll; margin-bottom: 20px">
        <div class="all-listing-container" v-for="license in licenses" :key="license">
          <router-link :to="`/products/license/${license.licenseId}`">
            <img :src="license.displayImage" />
            <div class="license-name">{{ license.name }}</div>
          </router-link>
        </div>
      </div>

      <div style="padding-bottom: 20px; position: relative">
        <div class="sub-header" style="display: flex; align-items: center; justify-content: flex-end">
          <span @click="isShowFilter = !isShowFilter" style="cursor: pointer">Filter</span>
          <span v-if="isShowFilter" class="material-icons" @click="isShowFilter = !isShowFilter" style="cursor: pointer">close</span>
        </div>
        <div style="position: absolute; right: 0; z-index: 1; width: 50%" v-if="!isMobileView">
          <ProductFilter
            style="box-shadow: 1px 1px 12px 5px rgba(0, 0, 0, 0.12); padding: 20px 20px 0 20px"
            v-show="isShowFilter"
            v-model:selectedScales="filter.scales"
            v-model:minPrice="filter.minPrice"
            v-model:maxPrice="filter.maxPrice"
            :applyFunc="applyFilter"
            :resetFunc="resetFilter"
          />
        </div>
        <el-dialog v-if="isMobileView" v-model="isShowFilter" :center="true" custom-class="custom-dialog ">
          <ProductFilter
            v-show="isShowFilter"
            v-model:selectedScales="filter.scales"
            v-model:minPrice="filter.minPrice"
            v-model:maxPrice="filter.maxPrice"
            :applyFunc="applyFilter"
            :resetFunc="resetFilter"
          />
        </el-dialog>
      </div>

      <el-row :gutter="20" v-if="!loading && products.length > 0">
        <el-col v-for="product in pagination.data" :key="product" :xs="12" :sm="12" :md="8" :lg="6" :xl="6">
          <router-link :to="`/product/${product.productId}`">
            <ProductCard :productDetail="product" />
          </router-link>
        </el-col>
      </el-row>

      <el-row :gutter="20" v-if="loading">
        <el-col :xs="12" :sm="12" :md="8" :lg="6" :xl="6" v-for="index in 10" :key="index">
          <ProductCardLoader />
        </el-col>
      </el-row>

      <el-empty description="No products." v-if="!loading && products.length === 0" />

      <div style="text-align: right" v-if="!loading && products.length > 0">
        <el-pagination
          layout="prev, pager, next"
          :total="pagination.totalRecord"
          :page-size="pagination.itemPerPage"
          :current-page="pagination.currentPage + 1"
          @current-change="paginationCallback"
        ></el-pagination>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import { ref, onMounted, computed, reactive } from 'vue';
import { useStore } from 'vuex';
import productServices from '@/services/product-service';
import configurationServices from '@/services/configuration-service';
import { CONFIGURATION_NAMES } from '@/common/constants';
import ProductCard from '@/components/Product/ProductCard.vue';
import ProductCardLoader from '@/components/Product/ProductCardLoader.vue';
import ProductFilter from '@/components/Product/ProductFilter.vue';
// import { debounceFunc } from '@/helpers';

const defaultPagination = {
  itemPerPage: 12,
  totalRecord: 0,
  currentPage: 0,
  data: [],
};

export default {
  components: {
    ProductCard,
    ProductCardLoader,
    ProductFilter,
  },
  setup() {
    const loading = ref(false);
    const productsOri = ref([]);
    const products = ref([]);
    const store = useStore();
    const isMobileView = computed(() => store.state.layout.isMobileView);
    const scales = ref([]);
    const licenses = computed(() => store.state.layout.licenses);
    const filter = reactive({
      scales: [],
      minPrice: '',
      maxPrice: '',
    });
    const isShowFilter = ref(false);

    const pagination = ref({
      ...defaultPagination,
    });

    const paginateProductList = (paginationParams) => {
      window.scrollTo(0, 0);
      pagination.value = {
        itemPerPage: paginationParams.itemPerPage,
        totalRecord: products.value.length,
        currentPage: paginationParams.currentPage,
        data: products.value.slice(
          paginationParams.itemPerPage * paginationParams.currentPage,
          paginationParams.itemPerPage * (paginationParams.currentPage + 1)
        ),
      };
    };

    const paginationCallback = (page) => {
      const newPagination = {
        ...pagination.value,
        currentPage: page - 1,
      };
      paginateProductList({
        ...newPagination,
      });
    };

    onMounted(async () => {
      loading.value = true;

      await Promise.all([configurationServices.getConfigurationByName(CONFIGURATION_NAMES.productScale), productServices.getProducts()])
        .then((result) => {
          scales.value = [...result[0][0].configurations];
          const productResult = [...result[1]].sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
          productsOri.value = productResult;
          products.value = productResult;
        })
        .catch(() => {});

      paginateProductList(pagination.value);

      loading.value = false;
    });

    const applyFilter = () => {
      isShowFilter.value = false;
      products.value = [...productsOri.value];
      if (filter.scales.length > 0) {
        products.value = products.value.filter((x) => filter.scales.some((y) => y === x.scale));
      }

      if (filter.minPrice !== '') {
        products.value = products.value.filter((x) => x.listingPrice >= filter.minPrice);
      }

      if (filter.maxPrice !== '') {
        products.value = products.value.filter((x) => x.listingPrice <= filter.maxPrice);
      }

      paginateProductList(pagination.value);
    };

    const resetFilter = () => {
      isShowFilter.value = false;
      products.value = [...productsOri.value];
      paginateProductList(pagination.value);
      Object.assign(filter, {
        scales: [],
        minPrice: '',
        maxPrice: '',
      });
    };

    return {
      products,
      isMobileView,
      licenses,
      scales,
      filter,
      pagination,
      loading,
      isShowFilter,
      applyFilter,
      resetFilter,
      paginationCallback,
    };
  },
};
</script>
