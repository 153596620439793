<template>
  <div v-if="productDetail" class="h-100">
    <el-row
      style="justify-content: center;"
      class="h-100"
      :style="{ flexDirection: isMobileView ? 'row' : 'row-reverse' }"
    >
      <el-col :span="24" :md="8">
        <div
          class="payment-product-container"
          :style="{ padding: isMobileView ? '20px' : '60px 40px 20px' }"
        >
          <section class="seller-section" :style="{ textAlign: isMobileView ? 'center' : 'left' }">
            <span class="sub-header fs-20" style="padding-right: 20px;">Seller:</span>
            <span v-if="sellerDetail">{{ sellerDetail.userName }}</span>
          </section>
          <section class="product-section">
            <img class="payment-img" :src="productDetail.images[0].productImageUrl" />
            <div style="width: 100%; padding-left: 10px; display: flex; flex-flow: column;">
              <div
                class="sub-header fs-20"
                style="padding-bottom: 10px"
              >{{ productDetail.serialNumber }}</div>
              <div class="sub-header fs-20" style="padding-bottom: 10px">{{ productDetail.name }}</div>
              <div
                class="sub-header fs-20"
                style="margin-top: auto;"
              >{{ `$${productDetail.listingPrice.toLocaleString(undefined, {minimumFractionDigits: 2})}` }}</div>
            </div>
          </section>
          <el-divider></el-divider>
          <section>
            <div class="bill-section">
              <div class="sub-header fs-20">Subtotal</div>
              <div>{{ `$${productDetail.listingPrice.toLocaleString(undefined, {minimumFractionDigits: 2})}` }}</div>
            </div>
            <div class="bill-section" style="padding-top: 20px;">
              <div class="sub-header fs-20">Shipping Fee</div>
              <div>{{ `$${productDetail.shippingFee.toLocaleString(undefined, {minimumFractionDigits: 2})}` }}</div>
            </div>
          </section>
          <el-divider></el-divider>
          <section>
            <div class="bill-section">
              <div class="sub-header fs-20">Total</div>
              <div class="total">
                <span style="padding-right: 20px;">SGD</span>
                <span
                  v-if="transactionDetail"
                  class="sub-header fs-24"
                >{{ `$${transactionDetail.price.toLocaleString(undefined, {minimumFractionDigits: 2})}` }}</span>
              </div>
            </div>
          </section>
        </div>
      </el-col>
      <el-col :span="24" :md="12">
        <div :style="{ padding: isMobileView ? '20px' : '0 40px' }" style="height: 100%;">
          <PaymentForm
            :onSubmit="submitPayment"
            :resetForm="resetForm"
            :isConfirmation="isConfirmation"
            :prodId="prodId"
            :buttonLabel="buttonLabel"
            :isSubmitingPayment="isSubmitingPayment"
            :backFunc="backFunc"
          />
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ElMessage } from 'element-plus';
import productServices from '@/services/product-service';
import { isLoggin, getAuthID } from '@/helpers';
import profileServices from '@/services/profile-service';
import transactionServices from '@/services/transaction-service';
import PaymentForm from '@/components/Payment/PaymentForm.vue';
import { useStore } from 'vuex';

export default {
  components: {
    PaymentForm,
  },
  setup() {
    const isConfirmation = ref(false);
    const route = useRoute();
    const router = useRouter();
    const productDetail = ref(null);
    const transactionDetail = ref(null);
    const sellerDetailLoading = ref(true);
    const sellerDetail = ref(null);
    const isSubmitingPayment = ref(false);
    const store = useStore();
    const isMobileView = computed(() => store.state.layout.isMobileView);

    const getProfile = () => {
      profileServices
        .getProfilebyUserId(productDetail.value.createdByUserId)
        .then((data) => {
          sellerDetail.value = data;
        })
        .finally(() => {
          sellerDetailLoading.value = false;
        });
    };

    const isOwner = () => {
      if (!productDetail.value || !getAuthID()) {
        return false;
      }
      return productDetail.value.createdByUserId === getAuthID().userId;
    };

    const backFunc = () => {
      if (isConfirmation.value) {
        isConfirmation.value = false;
      } else {
        router.push(`/profile/transactions?id=${route.params.transactionid}`);
      }
    };

    const submitPayment = (form) => {
      form.validate((valid) => {
        if (valid) {
          if (!isConfirmation.value) {
            isConfirmation.value = true;
          } else {
            isSubmitingPayment.value = true;
            const paymentDetails = {
              ...form.model,
              name: `${form.model.firstName} ${form.model.lastName}`,
            };
            transactionServices
              .makePayment(route.params.transactionid, paymentDetails)
              .then(
                (data) => {
                  window.location.replace(data.paymentUrl);
                },
                (error) => {
                  ElMessage({
                    showClose: true,
                    message: error,
                    type: 'error',
                  });
                },
              )
              .finally(() => {
                isSubmitingPayment.value = false;
              });
          }
        }
      });
    };

    onMounted(async () => {
      await Promise.all([
        productServices.getProductById(route.params.productid),
        transactionServices.getTransactionDetail(route.params.transactionid),
        productServices.getOffersByProductId(route.params.productid),
        productServices.getBidsByProductId(route.params.productid),
      ])
        .then((result) => {
          productDetail.value = {
            ...result[0],
          };

          if (result[0].salesType === 'Fixed' && result[2].length > 0) {
            const acceptOffer = result[2].find((x) => x.status === 'Accepted');
            productDetail.value.listingPrice = acceptOffer.price;
          }

          if (result[0].salesType === 'Auction' && result[3].length > 0) {
            const acceptBid = result[3].find((x) => x.status === 'Accepted');
            productDetail.value.listingPrice = acceptBid.price;
          }

          transactionDetail.value = {
            ...result[1],
          };

          if (transactionDetail.value.paymentUrl !== '' && transactionDetail.value.paymentUrl !== null) {
            window.location.replace(transactionDetail.value.paymentUrl);
          }
        })
        .catch(() => {});
      getProfile();
    });

    const resetForm = (form) => {
      form.resetFields();
    };

    return {
      productDetail,
      transactionDetail,
      sellerDetail,
      sellerDetailLoading,
      submitPayment,
      isLoggin,
      isSubmitingPayment,
      isOwner,
      resetForm,
      isConfirmation,
      isMobileView,
      backFunc,
    };
  },
};
</script>

<style lang="scss" scoped>
.product-desc {
  white-space: pre-wrap;
  overflow-wrap: break-word;
}
</style>
