<template>
  <el-row v-loading="loading">
    <el-col
      :span="22"
      :offset="1"
      :md="{ span: 18, offset: 3 }"
      style="margin-bottom: 15px; margin-top: 50px"
    >
      <div
        class="sub-header left-text"
        style="padding-bottom: 20px;"
        v-if="currentLicense"
      >{{ currentLicense.name }}</div>
      <div
        class="custom-scrollbar"
        style="white-space: nowrap; overflow-x: scroll; margin-bottom: 20px;"
      >
        <div
          class="all-listing-container"
          :class="selectedCharacter === character.characterId ? 'selected' : ''"
          v-for="character in licenseCharacters"
          :key="character"
          @click="characterOnClick(character.characterId)"
        >
          <img :src="character.displayImage" />
          <div class="license-name">{{ character.name }}</div>
        </div>
      </div>

      <div style="padding-bottom: 20px; position: relative;">
        <div
          class="sub-header"
          style="display: flex; align-items: center; justify-content: flex-end;"
        >
          <span @click="isShowFilter = !isShowFilter" style="cursor: pointer;">Filter</span>
          <span
            v-if="isShowFilter"
            class="material-icons"
            @click="isShowFilter = !isShowFilter"
            style="cursor: pointer;"
          >close</span>
        </div>
        <div style="position: absolute; right: 0; z-index: 1; width: 50%;" v-if="!isMobileView">
          <ProductFilter
            style="box-shadow: 1px 1px 12px 5px rgba(0,0,0,0.12); padding: 20px 20px 0 20px;"
            v-show="isShowFilter"
            :licenseId="$route.params.id"
            v-model:selectedScales="filter.scales"
            v-model:selectedSeries="filter.series"
            v-model:minPrice="filter.minPrice"
            v-model:maxPrice="filter.maxPrice"
            :applyFunc="applyFilter"
            :resetFunc="resetFilter"
          />
        </div>
        <el-dialog
          v-if="isMobileView"
          v-model="isShowFilter"
          :center="true"
          custom-class="custom-dialog "
        >
          <ProductFilter
            v-show="isShowFilter"
            :licenseId="$route.params.id"
            v-model:selectedScales="filter.scales"
            v-model:selectedSeries="filter.series"
            v-model:minPrice="filter.minPrice"
            v-model:maxPrice="filter.maxPrice"
            :applyFunc="applyFilter"
            :resetFunc="resetFilter"
          />
        </el-dialog>
      </div>

      <el-row :gutter="20" v-if="!loading && products.length > 0">
        <el-col
          v-for="product in pagination.data"
          :key="product"
          :xs="12"
          :sm="12"
          :md="8"
          :lg="6"
          :xl="6"
        >
          <router-link :to="`/product/${product.productId}`">
            <ProductCard :productDetail="product" />
          </router-link>
        </el-col>
      </el-row>

      <el-row :gutter="20" v-if="loading">
        <el-col :xs="12" :sm="12" :md="8" :lg="6" :xl="6" v-for="index in 10" :key="index">
          <ProductCardLoader />
        </el-col>
      </el-row>

      <el-empty description="No products." v-if="!loading && products.length === 0" />

      <div style="text-align: right;" v-if="!loading && products.length > 0">
        <el-pagination
          layout="prev, pager, next"
          :total="pagination.totalRecord"
          :page-size="pagination.itemPerPage"
          :current-page="pagination.currentPage + 1"
          @current-change="paginationCallback"
        ></el-pagination>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import {
  ref, onMounted, computed, watch, reactive,
} from 'vue';
import { useStore } from 'vuex';
import { useRoute, onBeforeRouteUpdate } from 'vue-router';
import productServices from '@/services/product-service';
import configurationServices from '@/services/configuration-service';
import { CONFIGURATION_NAMES } from '@/common/constants';
import ProductCard from '@/components/Product/ProductCard.vue';
import ProductCardLoader from '@/components/Product/ProductCardLoader.vue';
import ProductFilter from '@/components/Product/ProductFilter.vue';

const defaultPagination = {
  itemPerPage: 12,
  totalRecord: 0,
  currentPage: 0,
  data: [],
};

export default {
  components: {
    ProductCard,
    ProductCardLoader,
    ProductFilter,
  },
  setup() {
    const route = useRoute();
    const loading = ref(false);
    const productsOri = ref([]);
    const products = ref([]);
    const store = useStore();
    const isMobileView = computed(() => store.state.layout.isMobileView);
    const scales = ref([]);
    const allLicenses = computed(() => store.state.layout.licenses);
    const licenseCharacters = ref([]);
    const selectedCharacter = ref('');
    const currentLicense = ref(null);
    const filter = reactive({
      series: [],
      scales: [],
      minPrice: '',
      maxPrice: '',
    });
    const isShowFilter = ref(false);

    const pagination = ref({
      ...defaultPagination,
    });

    const paginateProductList = (paginationParams) => {
      window.scrollTo(0, 0);
      pagination.value = {
        itemPerPage: paginationParams.itemPerPage,
        totalRecord: products.value.length,
        currentPage: paginationParams.currentPage,
        data: products.value.slice(
          paginationParams.itemPerPage * paginationParams.currentPage,
          paginationParams.itemPerPage * (paginationParams.currentPage + 1),
        ),
      };
    };

    const paginationCallback = (page) => {
      const newPagination = {
        ...pagination.value,
        currentPage: page - 1,
      };
      paginateProductList({
        ...newPagination,
      });
    };

    const initialCall = async (license) => {
      isShowFilter.value = false;
      loading.value = true;
      currentLicense.value = allLicenses.value.find((x) => x.licenseId === license);
      await Promise.all([
        configurationServices.getConfigurationByName(CONFIGURATION_NAMES.productScale),
        productServices.getProductByLicenseId(license),
        productServices.retrieveCharactersByLicenseId(license),
      ])
        .then((result) => {
          scales.value = [...result[0][0].configurations];
          const productResult = [...result[1]].sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
          productsOri.value = productResult;
          products.value = productResult;
          licenseCharacters.value = [...result[2]];
        })
        .catch(() => {});

      paginateProductList(pagination.value);

      loading.value = false;
    };

    onBeforeRouteUpdate((to) => {
      initialCall(to.params.id);
    });

    onMounted(() => {
      initialCall(route.params.id);
    });

    watch(
      () => allLicenses.value,
      (newLicenses) => {
        currentLicense.value = newLicenses.find((x) => x.licenseId === route.params.id);
      },
    );

    const characterOnClick = (character) => {
      if (character === selectedCharacter.value) {
        selectedCharacter.value = '';
        products.value = [...productsOri.value];
        paginateProductList(pagination.value);
      } else {
        selectedCharacter.value = character;
        products.value = productsOri.value.filter((x) => x.characters.some((y) => y.characterId === character));
        paginateProductList(pagination.value);
      }
    };

    const applyFilter = () => {
      isShowFilter.value = false;
      products.value = [...productsOri.value];
      if (filter.series.length > 0) {
        products.value = products.value.filter((x) => filter.series.some((y) => y === x.series.name));
      }

      if (filter.scales.length > 0) {
        products.value = products.value.filter((x) => filter.scales.some((y) => y === x.scale));
      }

      if (filter.minPrice !== '') {
        products.value = products.value.filter((x) => x.listingPrice >= filter.minPrice);
      }

      if (filter.maxPrice !== '') {
        products.value = products.value.filter((x) => x.listingPrice <= filter.maxPrice);
      }

      paginateProductList(pagination.value);
    };

    const resetFilter = () => {
      isShowFilter.value = false;
      products.value = [...productsOri.value];
      paginateProductList(pagination.value);
      Object.assign(filter, {
        scales: [],
        minPrice: '',
        maxPrice: '',
      });
    };

    return {
      products,
      currentLicense,
      scales,
      isMobileView,
      pagination,
      loading,
      licenseCharacters,
      paginationCallback,
      selectedCharacter,
      isShowFilter,
      characterOnClick,
      filter,
      applyFilter,
      resetFilter,
    };
  },
};
</script>
