<template>
  <div class="countdown-timer" v-if="countdownDetail">
    <!-- {{ `
    ${countdownDetail.days} days
    ${countdownDetail.hours} hrs
    ${countdownDetail.minutes} min
    ${countdownDetail.seconds} sec`
    }} -->
    {{ `
    ${countdownDetail.days} days
    ${countdownDetail.hours} hrs
    ${countdownDetail.minutes} min`
    }}
    <!-- <span v-if="countdownDetail.days > 0">{{`${countdownDetail.days} days `}}</span>
    <span v-if="countdownDetail.hours > 0 || countdownDetail.days > 0">{{`${countdownDetail.hours} hrs `}}</span>
    <span>{{`${countdownDetail.minutes} min `}}</span> -->
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { getTimeRemainingFromNow } from '@/helpers';

export default {
  props: {
    endDate: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const countdownDetail = ref(null);

    const getRemainingTime = () => {
      countdownDetail.value = getTimeRemainingFromNow(props.endDate);
    };

    onMounted(() => {
      getRemainingTime();
      setInterval(() => {
        getRemainingTime();
      }, 1000);
    });

    return {
      countdownDetail,
    };
  },
};
</script>
