<template>
  <el-main>
    <div class="product-detail-container" v-if="productDetail">
      <el-row>
        <el-col :span="24" :sm="{ span: 20, offset: 2 }">
          <div>
            <el-row>
              <el-col :span="24" :xs="24" :sm="12">
                <div class="image-container">
                  <img :src="defaultPreviewImage" />
                </div>
                <div class="preview-container custom-scrollbar">
                  <div
                    v-for="images in productDetail.images"
                    :key="images.productImageUrl"
                    class="preview-image-wrapper"
                    :class="{ selected: images.productImageUrl == defaultPreviewImage }"
                    :style="{ backgroundImage: `url(${images.productImageUrl})` }"
                    @click="defaultPreviewImage = images.productImageUrl"
                  ></div>
                </div>
              </el-col>
              <el-col :span="24" :xs="24" :sm="{ span: 11, offset: 1 }">
                <div>
                  <div class="left-text fs-16" style="padding-bottom: 10px">
                    <el-skeleton :loading="userDetailLoading" animated>
                      <template #template>
                        <el-skeleton-item variant="text" style="width: 30%" />
                      </template>
                      <template #default>
                        <Username :profileDetail="userDetail" />
                      </template>
                    </el-skeleton>
                  </div>
                  <div
                    class="sub-header fs-20"
                    style="padding-bottom: 10px"
                  >{{ productDetail.serialNumber }}</div>
                  <div
                    class="sub-header fs-20"
                    style="padding-bottom: 10px"
                  >{{ productDetail.name }}</div>
                  <div
                    class="left-text fs-20"
                    style="padding-bottom: 10px"
                  >{{ `${productDetail.series.license.name}, ${productDetail.scale} Scale` }}</div>
                  <div
                    class="left-text fs-20"
                    style="padding-bottom: 10px"
                  >{{ productDetail.distributor }}</div>
                </div>
                <div class="sales-detail-container">
                  <div v-if="productDetail.salesType === 'Fixed'">
                    <div
                      class="sub-header fs-24"
                      style="padding-bottom: 20px"
                    >{{ `SGD$${productDetail.listingPrice.toLocaleString(undefined, {minimumFractionDigits: 2})}` }}</div>
                    <div
                      style="padding-top: 10px; padding-bottom: 20px"
                      v-if="productDetail.status == 'Active'"
                    >
                      <el-row class="mb-4" v-if="!isOwner()">
                        <el-col :span="24">
                          <el-button
                            class="custom-btn primary-btn"
                            style="margin-right: 20px;"
                            @click="buyNowDialog"
                          >
                            <span class="buy-button-wide">BUY AT THIS PRICE</span>
                            <span class="buy-button-small">BUY</span>
                          </el-button>
                          <el-button class="custom-btn primary-btn" @click="offerDialog">
                            <span class="offer-button-wide">MAKE AN OFFER</span>
                            <span class="offer-button-small">OFFER</span>
                          </el-button>
                          <span
                            style="margin-left: 10px; vertical-align: bottom;"
                            v-if="isLoggin()"
                          >
                            <ToggleFavorite :productId="productDetail.productId" />
                          </span>
                        </el-col>
                      </el-row>
                    </div>
                  </div>
                  <div v-else>
                    <el-row :gutter="20" style="overflow-wrap: break-word;">
                      <el-col :span="12" style="padding-bottom: 20px">
                        <div>
                          <div class="fs-12">Starting from</div>
                          <div
                            class="sub-header fs-24"
                            style="opacity: 0.6;"
                          >{{ `SGD$${productDetail.listingPrice.toLocaleString(undefined, {minimumFractionDigits: 2})}` }}</div>
                        </div>
                      </el-col>
                      <el-col :span="12" style="padding-bottom: 20px">
                        <div>
                          <div class="fs-12">Auction end date</div>
                          <div class="sub-header fs-24">
                            <span
                              v-if="!isAuctionEnd"
                            >{{ dayjs(productDetail.auctionEndDate).format('DD/MM/YYYY hh:mm A') }}</span>
                            <span style="opacity: 0.6;" v-else>Auction enede</span>
                          </div>
                        </div>
                      </el-col>
                      <el-col :span="12" style="padding-bottom: 20px">
                        <div>
                          <div class="fs-12">Current bid</div>
                          <div
                            class="sub-header fs-24"
                          >{{ `SGD$${getCurrentBidAmount().toLocaleString(undefined, {minimumFractionDigits: 2})}` }}</div>
                        </div>
                      </el-col>
                      <el-col :span="12" style="padding-bottom: 20px">
                        <div>
                          <div class="fs-12">Current bidders</div>
                          <div class="sub-header fs-24 text-center">{{ productBids.length }}</div>
                        </div>
                      </el-col>
                      <el-col :span="24" v-if="!isAuctionEnd" style="padding-bottom: 20px">
                        <div class="main-header">
                          <Countdown :endDate="productDetail.auctionEndDate" />
                        </div>
                      </el-col>
                      <el-col
                        :span="24"
                        style="padding-top: 10px; padding-bottom: 20px;"
                        v-if="!isOwner()"
                      >
                        <div v-if="productDetail.status == 'Active'">
                          <el-button
                            :disabled="isAuctionEnd"
                            class="custom-btn primary-btn"
                            @click="bidDialog"
                          >Place a bid</el-button>
                          <span
                            style="margin-left: 10px; vertical-align: bottom;"
                            v-if="isLoggin()"
                          >
                            <ToggleFavorite :productId="productDetail.productId" />
                          </span>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                  <div>
                    <el-row class="mb-4" v-if="!isOwner() && isLoggin()">
                      <el-col :xs="24" :sm="24" :md="16">
                        <el-button
                          style="width: 100%;"
                          class="custom-btn primary-btn"
                          @click="chatDialogVisible = true"
                        >Chat With Seller</el-button>
                      </el-col>
                    </el-row>
                  </div>
                  <div v-if="isOwner()">
                    <el-row class="mb-4">
                      <el-col :span="24">
                        <div>
                          <div
                            :class="!isAbleToEdit ? 'disabled' : ''"
                            style="cursor: pointer;"
                            @click="editListing"
                          >
                            <i class="fa fa-pencil" aria-hidden="true" style="padding-right: 8px;"></i>Edit Listing
                          </div>
                          <div
                            :class="!isAbleToEdit ? 'disabled' : ''"
                            style="color: #AC3131; cursor: pointer; padding-top: 5px;"
                            @click="deleteToggle"
                          >
                            <i class="fa fa-trash" aria-hidden="true" style="padding-right: 8px;"></i>Delete Listing
                          </div>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" :sm="{ span: 20, offset: 2 }">
          <div style="width: 100%; padding: 30px 0;">
            <div class="sub-header left-text fs-20">Description</div>
            <div class="left-text fs-16 product-desc">{{ productDetail.description }}</div>
          </div>

          <div style="width: 100%; ">
            <div class="sub-header left-text fs-20">Blockchain Events</div>
            <div class="container" v-if="provenanceDetails">
              <div class="rightbox">
                <div class="rb-container">
                  <ul class="rb" >
                    <li class="rb-item" v-for="item in provenanceDetails" :key="item.provenanceEventId">
                      <div class="title">{{ item.event }}</div>
                      <div class="timestamp">{{ formatTimestamp(item.eventTime) }}</div>
                      <div class="item-title">HASH: {{ item.provenanceEventHash ? item.provenanceEventHash.toUpperCase() : '-' }}</div>
                    </li>
                  </ul>
                  
                </div>
              </div>
            </div>
            <div v-else class="no-event">No blockchain event</div>
          </div>

          <div class="additional-info">
            <div class="sub-header fs-20 left-text">Characters</div>
            <div class="left-text fs-16">{{ productCharacters }}</div>
          </div>
          <div class="additional-info">
            <div class="sub-header fs-20 left-text">Series</div>
            <div class="left-text fs-16">{{ productDetail.series.name }}</div>
          </div>
          <div class="additional-info">
            <div class="sub-header fs-20 left-text">Distributor</div>
            <div class="left-text fs-16">{{ productDetail.distributor }}</div>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" :sm="{ span: 20, offset: 2 }">
          <ProductPrices
            :productDetail="productDetail"
            :productPricesList="productDetail.salesType === 'Fixed' ? productOffers : productBids"
          />
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" :sm="{ span: 20, offset: 2 }">
          <div style="width: 100%; margin-top: 20px;">
            <span class="sub-header fs-20">More like this</span>
            <br />
          </div>
          <div style="width: 100%; margin-top: 20px;">
            <el-row :gutter="20">
              <el-col
                v-for="product in similarProducts"
                :key="product"
                :xs="12"
                :sm="12"
                :md="8"
                :lg="6"
                :xl="6"
              >
                <router-link :to="`/product/${product.productId}`">
                  <ProductCard :productDetail="product" />
                </router-link>
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
  </el-main>

  <el-dialog v-model="dialogVisible" :center="true" custom-class="custom-dialog place-bid-dialog">
    <div>
      <div class="main-header center-text">Place a bid</div>
      <div class="dialog-info-wrapper">
        <div class="sub-header">Current bid</div>
        <div
          class="right-text"
        >{{ `SGD$${getCurrentBidAmount().toLocaleString(undefined, {minimumFractionDigits: 2})}` }}</div>
      </div>
      <div class="dialog-info-wrapper">
        <div class="sub-header">Minimum Increment</div>
        <div
          class="right-text"
        >{{ `SGD$${productDetail.minAuctionIncrement.toLocaleString(undefined, {minimumFractionDigits: 2})}` }}</div>
      </div>
      <div class="dialog-info-wrapper">
        <div class="sub-header">Shipping Fee</div>
        <div
          class="right-text"
        >{{ `SGD$${productDetail.shippingFee.toLocaleString(undefined, {minimumFractionDigits: 2})}` }}</div>
      </div>
      <div class="bid-input-wrapper">
        <div>SGD$</div>
        <div>
          <el-form
            class="custom-form"
            label-position="top"
            label-width="100px"
            :model="bidModel"
            :rules="bidRules"
            ref="bidForm"
            @keyup.enter="submitBid"
            @submit.prevent
          >
            <TextInput
              inputType="number"
              v-model="bidModel.bidAmount"
              formProps="bidAmount"
              formLabel="Amount"
            />
          </el-form>
        </div>
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <!-- <el-button
          :disabled="isSubmitingBid"
          class="custom-btn primary-btn"
          :loading="isSubmitingBid"
          @click="submitBid"
        >Place a bid</el-button> -->
        <el-button
          :disabled="isSubmitingBid"
          class="custom-btn accept-btn"
          :loading="isSubmitingBid"
          @click="submitBid"
        >Place a bid</el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog
    v-model="buyNowDialogVisible"
    :center="true"
    custom-class="custom-dialog place-bid-dialog"
  >
    <div>
      <div class="main-header center-text">Buy Now</div>
      <div class="dialog-info-wrapper">
        <div class="sub-header">Listed Price</div>
        <div
          class="right-text"
        >{{ `SGD$${productDetail.listingPrice.toLocaleString(undefined, {minimumFractionDigits: 2})}` }}</div>
      </div>
      <div class="dialog-info-wrapper">
        <div class="sub-header">Shipping Fee</div>
        <div
          class="right-text"
        >{{ `SGD$${productDetail.shippingFee.toLocaleString(undefined, {minimumFractionDigits: 2})}` }}</div>
      </div>
      <el-divider></el-divider>
      <div class="dialog-info-wrapper" style="padding-top: 0;">
        <div class="sub-header">Total</div>
        <div
          class="right-text"
        >{{ `SGD$${(productDetail.listingPrice + productDetail.shippingFee).toLocaleString(undefined, {minimumFractionDigits: 2})}` }}</div>
      </div>
      <div
        style="text-align: center; padding-top: 30px; font-weight: bold; font-family: 'Montserrat';"
      >Purchase at the listed price?</div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button class="custom-btn secondary-btn" @click="buyNowDialogVisible=false;">Cancel</el-button>
        <!-- <el-button
          :disabled="isBuying"
          class="custom-btn primary-btn"
          :loading="isBuying"
          @click="createTransaction"
        >Confirm</el-button> -->
        <el-button
          :disabled="isBuying"
          class="custom-btn accept-btn"
          :loading="isBuying"
          @click="createTransaction"
        >Confirm</el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog
    v-model="offerDialogVisible"
    :center="true"
    custom-class="custom-dialog place-bid-dialog"
  >
    <div>
      <div class="main-header center-text">Make an offer</div>
      <div class="dialog-info-wrapper">
        <div class="sub-header">Listed Price</div>
        <div
          class="right-text"
        >{{ `SGD$${productDetail.listingPrice.toLocaleString(undefined, {minimumFractionDigits: 2})}` }}</div>
      </div>
      <div class="dialog-info-wrapper">
        <div class="sub-header">Shipping Fee</div>
        <div
          class="right-text"
        >{{ `SGD$${productDetail.shippingFee.toLocaleString(undefined, {minimumFractionDigits: 2})}` }}</div>
      </div>
      <div class="bid-input-wrapper">
        <div>SGD$</div>
        <div>
          <el-form
            class="custom-form"
            label-position="top"
            label-width="100px"
            :model="offerModel"
            :rules="offerRules"
            ref="offerForm"
            @keyup.enter="submitOffer"
            @submit.prevent
          >
            <TextInput
              inputType="number"
              v-model="offerModel.offerAmount"
              formProps="offerAmount"
              formLabel="Amount"
            />
          </el-form>
        </div>
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <!-- <el-button
          :disabled="isSubmitingOffer"
          class="custom-btn primary-btn"
          :loading="isSubmitingOffer"
          @click="submitOffer"
        >Place offer</el-button> -->
        <el-button
          :disabled="isSubmitingOffer"
          class="custom-btn accept-btn"
          :loading="isSubmitingOffer"
          @click="submitOffer"
        >Place offer</el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog
    v-model="deleteDialogVisible"
    :center="true"
    custom-class="custom-dialog place-bid-dialog"
  >
    <div>
      <div class="main-header center-text">Delete Listing?</div>
      <div class="bid-input-wrapper">
        <div style="text-align: center;">
          Your listing will be permanently deleted. This action is irreversible.
          Do you want to continue?
        </div>
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button class="custom-btn secondary-btn" @click="deleteDialogVisible=false;">Cancel</el-button>
        <el-button
          :disabled="isDeleting"
          class="custom-btn primary-btn"
          :loading="isDeleting"
          @click="deleteListing"
        >Delete</el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog
    v-model="chatDialogVisible"
    :center="true"
    custom-class="custom-dialog place-bid-dialog"
  >
    <div>
      <div class="main-header center-text">Chat With Seller</div>
      <div class="bid-input-wrapper">
        <div>
          <el-form
            class="custom-form"
            label-position="top"
            label-width="100px"
            :model="chatModel"
            :rules="chatRules"
            ref="chatForm"
            @keyup.enter="sendMessage"
            @submit.prevent
          >
            <TextArea v-model="chatModel.chatMessage" formProps="message" formLabel="Message" />
          </el-form>
        </div>
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <!-- <el-button
          :disabled="messageSending"
          class="custom-btn primary-btn"
          :loading="messageSending"
          @click="sendMessage"
        >Send</el-button> -->
        <el-button
          :disabled="messageSending"
          class="custom-btn accept-btn"
          :loading="messageSending"
          @click="sendMessage"
        >Send</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import { useRoute, useRouter, onBeforeRouteUpdate } from 'vue-router';
import { ElMessage } from 'element-plus';
import productServices from '@/services/product-service';
import { isLoggin, getAuthID } from '@/helpers';
import profileServices from '@/services/profile-service';
import ProductCard from '@/components/Product/ProductCard.vue';
import ProductPrices from '@/components/Product/ProductPrices.vue';
import dayjs from 'dayjs';
import TextInput from '@/components/Share/TextInput.vue';
import TextArea from '@/components/Share/TextArea.vue';
import Countdown from '@/components/Product/Countdown.vue';
import ToggleFavorite from '@/components/Product/ToggleFavorite.vue';
import chatServices from '@/services/chat-service';
import transactionServices from '@/services/transaction-service';
import Username from '@/components/Profile/Username.vue';

export default {
  components: {
    ProductCard,
    TextInput,
    TextArea,
    Countdown,
    ProductPrices,
    ToggleFavorite,
    Username,
  },


  setup() {
    const route = useRoute();
    const router = useRouter();
    const productDetail = ref(null);
    const provenanceDetails = ref(null);
    const similarProducts = ref(null);
    const defaultPreviewImage = ref(null);
    const userDetailLoading = ref(true);
    const userDetail = ref(null);
    const productBids = ref([]);
    const productOffers = ref([]);
    const isAuctionEnd = ref(true);
    const dialogVisible = ref(false);
    const bidForm = ref(null);
    const isSubmitingBid = ref(false);
    const isSubmitingOffer = ref(false);
    const bidModel = ref({
      bidAmount: null,
    });
    const getCurrentBidAmount = () => (productBids.value.length > 0 ? productBids.value[0].price : productDetail.value.listingPrice);

    const bidRules = ref({
      bidAmount: [
        {
          required: true,
          message: 'Please enter amount',
        },
        {
          validator: (rule, value, callback) => {
            if (value <= getCurrentBidAmount() + productDetail.value.minAuctionIncrement) {
              callback(new Error('Amount must be more than current bid plus min auction amount'));
            } else {
              callback();
            }
          },
        },
      ],
    });

    const offerForm = ref(null);
    const offerDialogVisible = ref(false);
    const offerModel = ref({
      offerAmount: null,
    });

    const toUpperCase = (str) => {
      return str.toUpperCase();
    };

    const formatTimestamp = (timestamp) => {
      return dayjs(timestamp).format('YYYY-MM-DD HH:mm');
    }

    const offerRules = ref({
      offerAmount: [
        {
          required: true,
          message: 'Please enter amount',
        },
      ],
    });

    const getProfile = () => {
      profileServices
        .getProfilebyUserId(productDetail.value.createdByUserId)
        .then((data) => {
          userDetail.value = data;
        })
        .finally(() => {
          userDetailLoading.value = false;
        });
    };

    const getSimilarProducts = async () => {
      similarProducts.value = await productServices.getSimilarProductsById(route.params.id);
    };

    const getProductBids = async () => {
      productBids.value = await productServices.getBidsByProductId(route.params.id);
    };

    const getProductOffers = async () => {
      productOffers.value = await productServices.getOffersByProductId(route.params.id);
    };

    const isOwner = () => {
      if (!productDetail.value || !getAuthID()) {
        return false;
      }
      return productDetail.value.createdByUserId === getAuthID().userId;
    };

    const submitBid = () => {
      bidForm.value.validate((valid) => {
        if (valid) {
          isSubmitingBid.value = true;
          const bidDetail = {
            productId: route.params.id,
            price: Number(Number(bidModel.value.bidAmount).toFixed(2)),
          };
          productServices
            .placeBid(bidDetail)
            .then(
              () => {
                ElMessage({
                  showClose: true,
                  message: 'Place bid successfully',
                  type: 'success',
                });
              },
              (error) => {
                ElMessage({
                  showClose: true,
                  message: error,
                  type: 'error',
                });
              },
            )
            .finally(() => {
              getProductBids();
              isSubmitingBid.value = false;
              dialogVisible.value = false;
              bidForm.value.resetFields();
            });
        }
      });
    };

    const submitOffer = () => {
      offerForm.value.validate((valid) => {
        if (valid) {
          isSubmitingBid.value = true;
          const offerDetail = {
            productId: route.params.id,
            price: Number(Number(offerModel.value.offerAmount).toFixed(2)),
          };
          productServices
            .placeOffer(offerDetail)
            .then(
              async () => {
                ElMessage({
                  showClose: true,
                  message: 'Place offer successfully',
                  type: 'success',
                });
              },
              (error) => {
                ElMessage({
                  showClose: true,
                  message: error,
                  type: 'error',
                });
              },
            )
            .finally(() => {
              getProductOffers();
              isSubmitingOffer.value = false;
              offerDialogVisible.value = false;
              offerForm.value.resetFields();
            });
        }
      });
    };

    const initialCall = async (productId) => {
      productDetail.value = null;
      productDetail.value = await productServices.getProductById(productId);

      try {
        provenanceDetails.value = await productServices.getProvenanceBySerialNumber(productDetail.value.serialNumber);
        provenanceDetails.value = provenanceDetails.value.provenanceEvent;
      } catch (error) {
        console.error('Error fetching provenance details:', error);
      }

      defaultPreviewImage.value = productDetail.value.images[0].productImageUrl;
      getProfile();
      getProductBids();
      getProductOffers();
      getSimilarProducts();
      if (productDetail.value.salesType === 'Auction') {
        isAuctionEnd.value = !dayjs().isBefore(productDetail.value.auctionEndDate);
      } else {
        isAuctionEnd.value = false;
      }
    };

    onMounted(async () => {
      await initialCall(route.params.id);
    });

    const productCharacters = computed(() => {
      if (!productDetail.value.characters.lengt < 0) {
        return '';
      }
      return productDetail.value.characters.map((x) => x.name).join(', ');
    });

    const isAbleToEdit = computed(() => isOwner() && !isAuctionEnd.value && productBids.value.length === 0 && productOffers.value.length === 0);

    const editListing = () => {
      if (isAbleToEdit.value) {
        router.push(`/profile/uploadproduct?editid=${productDetail.value.productId}`);
      }
    };

    const deleteDialogVisible = ref(false);
    const isDeleting = ref(false);
    const deleteToggle = () => {
      if (isAbleToEdit.value) {
        deleteDialogVisible.value = true;
      }
    };
    const deleteListing = async () => {
      if (isAbleToEdit.value) {
        isDeleting.value = true;
        await productServices.deleteProduct(productDetail.value.productId);
        ElMessage({
          showClose: true,
          message: 'Delete listing successfully',
          type: 'success',
        });
        router.push('/profile/listings');
        isDeleting.value = false;
      }
    };

    onBeforeRouteUpdate((to) => {
      initialCall(to.params.id);
    });

    const buyNowDialogVisible = ref(false);
    const isBuying = ref(false);
    const buyNowDialog = () => {
      if (!isLoggin()) {
        router.push('/login');
      } else {
        buyNowDialogVisible.value = true;
      }
    };

    const createTransaction = async () => {
      isBuying.value = true;
      const transaction = await transactionServices.createTransaction(productDetail.value.productId);
      isBuying.value = false;
      buyNowDialogVisible.value = false;
      router.push(`/profile/transactions?id=${transaction.transactionId}`);
    };

    const offerDialog = () => {
      if (!isLoggin()) {
        router.push('/login');
      } else {
        offerDialogVisible.value = true;
      }
    };

    const bidDialog = () => {
      if (!isLoggin()) {
        router.push('/login');
      } else {
        dialogVisible.value = true;
      }
    };

    const messageSending = ref(false);
    const chatForm = ref(null);
    const chatDialogVisible = ref(false);
    const chatModel = ref({
      chatMessage: null,
    });

    const chatRules = ref({
      offerAmount: [
        {
          required: true,
          chatMessage: 'Please enter message',
        },
      ],
    });

    const sendMessage = async () => {
      messageSending.value = true;
      const messageBody = {
        senderUserId: getAuthID().userId,
        recipientUserId: productDetail.value.createdByUserId,
        message: chatModel.value.chatMessage,
      };

      await chatServices.sendMessage(messageBody);
      ElMessage({
        showClose: true,
        message: 'Message sent successfully',
        type: 'success',
      });
      chatModel.value.chatMessage = '';
      messageSending.value = false;
      chatDialogVisible.value = false;
    };

    return {
      productDetail,
      similarProducts,
      defaultPreviewImage,
      userDetail,
      userDetailLoading,
      productBids,
      productOffers,
      dayjs,
      isAuctionEnd,
      dialogVisible,
      bidForm,
      bidModel,
      bidRules,
      getCurrentBidAmount,
      submitBid,
      submitOffer,
      isLoggin,
      isSubmitingBid,
      isSubmitingOffer,
      isOwner,
      productCharacters,
      offerDialogVisible,
      offerForm,
      offerModel,
      offerRules,
      ProductPrices,
      ToggleFavorite,
      offerDialog,
      bidDialog,
      isAbleToEdit,
      editListing,
      deleteListing,
      deleteDialogVisible,
      deleteToggle,
      isDeleting,
      chatForm,
      chatDialogVisible,
      chatModel,
      chatRules,
      sendMessage,
      messageSending,
      buyNowDialogVisible,
      isBuying,
      buyNowDialog,
      createTransaction,
      provenanceDetails,
      toUpperCase,
      formatTimestamp
    };
  },
};
</script>

<style lang="scss" scoped>
.product-desc {
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.disabled {
  opacity: 0.5;
}
.container {
  margin: 0 auto;
  position: relative;
  margin-left: 20px
}


.rightbox {
  padding: 0em 34rem 0em 0em;
  height: 100%;
}

.rb-container {
  font-family: "PT Sans", sans-serif;
  margin: auto;
  display: block;
  position: relative;
}

.rb-container ul.rb {
  margin: 2.5em 0;
  padding: 0;
  display: inline-block;
  width: 1000px;
}

.rb-container ul.rb li {
  list-style: none;
  margin: auto;
  min-height: 50px;
  border-left: 1px dashed black;
  padding: 0 0 50px 30px;
  position: relative;
}

.rb-container ul.rb li:last-child {
  border-left: 0;
}

.rb-container ul.rb li::before {
  position: absolute;
  left: -18px;
  top: -5px;
  content: " ";
  border: 8px solid rgba(255, 255, 255, 1);
  border-radius: 500%;
  background: #50d890;
  height: 20px;
  width: 20px;
  transition: all 500ms ease-in-out;
}

.rb-container ul.rb li:hover::before {
  border-color: #232931;
  transition: all 1000ms ease-in-out;
}

ul.rb li .timestamp {
  color: #50d890;
  position: relative;
  width: 300px;
  font-size: 15px;
  padding-top:5px;
  padding-bottom:5px;
}

.item-title {
  color: black;
  font-size: 13px;
}

.title {
  font-size: 15px;
  font-weight: bold;
}

.no-event{
  font-size: 13px;
  padding-left: 5px;
  padding-top:5px;
  padding-bottom:20px;
}
</style>
