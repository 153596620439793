<template>
  <el-collapse class="product-filter-collapse">
    <el-collapse-item title="Series" name="Series" v-if="series.length > 0">
      <el-checkbox-group :model-value="selectedSeries">
        <el-checkbox
          v-for="seriesData in series"
          :key="seriesData"
          :label="seriesData.name"
          @change="handleSeries(seriesData.name)"
        />
      </el-checkbox-group>
    </el-collapse-item>
    <el-collapse-item title="Scale" name="Scale">
      <el-checkbox-group :model-value="selectedScales">
        <el-checkbox
          v-for="scale in scales"
          :key="scale"
          :label="scale.name"
          @change="handleScales(scale.name)"
        />
      </el-checkbox-group>
    </el-collapse-item>
    <el-collapse-item title="Price" name="Price">
      <el-input
        :modelValue="minPrice"
        type="number"
        placeholder="Minimun"
        @input="$emit('update:minPrice', $event)"
        style="padding-bottom: 10px;"
      ></el-input>
      <el-input
        :modelValue="maxPrice"
        type="number"
        placeholder="Maximum"
        @input="$emit('update:maxPrice', $event)"
      ></el-input>
    </el-collapse-item>
    <div style="margin: 20px 0; text-align: right;">
        <el-button class="custom-btn secondary-btn" size="small" @click="resetFunc">Reset</el-button>
        <el-button class="custom-btn primary-btn" size="small" @click="applyFunc">Apply</el-button>
    </div>
  </el-collapse>
</template>

<script>
import { ref, onMounted } from 'vue';
import configurationServices from '@/services/configuration-service';
import productServices from '@/services/product-service';
import { CONFIGURATION_NAMES } from '@/common/constants';

export default {
  props: {
    selectedScales: {
      type: Array,
      default() {
        return [];
      },
    },
    selectedSeries: {
      type: Array,
      default() {
        return [];
      },
    },
    licenseId: {
      type: String,
      default: null,
    },
    minPrice: {
      type: String,
      default: '',
    },
    maxPrice: {
      type: String,
      default: '',
    },
    applyFunc: {
      type: Function,
      required: true,
    },
    resetFunc: {
      type: Function,
      required: true,
    },
  },
  setup(props, context) {
    const isShow = ref(false);
    const series = ref([]);
    const scales = ref([]);

    onMounted(async () => {
      const initialApiArray = [configurationServices.getConfigurationByName(CONFIGURATION_NAMES.productScale)];

      if (props.licenseId !== null) {
        initialApiArray.push(productServices.retrieveSeriesByLicenseId(props.licenseId));
      }

      await Promise.all(initialApiArray)
        .then((result) => {
          scales.value = [...result[0][0].configurations];
          series.value = [...result[1]];
        })
        .catch(() => {});
    });

    const handleScales = (scaleName) => {
      let newSelected = [];
      if (props.selectedScales.includes(scaleName)) {
        const oldSelected = [...props.selectedScales];
        oldSelected.splice(
          props.selectedScales.findIndex((x) => x === scaleName),
          1,
        );
        newSelected = oldSelected;
      } else {
        newSelected = [...props.selectedScales, scaleName];
      }
      context.emit('update:selectedScales', newSelected);
    };

    const handleSeries = (seriesId) => {
      let newSelected = [];
      if (props.selectedSeries.includes(seriesId)) {
        const oldSelected = [...props.selectedSeries];
        oldSelected.splice(
          props.selectedSeries.findIndex((x) => x === seriesId),
          1,
        );
        newSelected = oldSelected;
      } else {
        newSelected = [...props.selectedSeries, seriesId];
      }
      context.emit('update:selectedSeries', newSelected);
    };

    return {
      isShow,
      scales,
      series,
      handleSeries,
      handleScales,
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
